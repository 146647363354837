import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";


function AboutPage() {

    const title = 'Specials';

    // let stores = data.allContentfulStores;
    return (
        <Layout useMain={true}>
            <SEO
                title={title}
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
            />

        </Layout>
    );
}

export default AboutPage;
